:root {
    overflow-y: hidden; 
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

body {
    margin: 0;
}

iframe
{
    display: none;
}