@import 'variables.scss';

.container {
    border-radius: 10px;
    direction: ltr;
    margin: 0;
    padding: 8px 0 8px 12px;
    transition: .2s;
    width: 100%;
    box-sizing: border-box;
}

.favicon {
    width: 16px;
    height: 16px;
    border-radius: 2px;
}

.metadata {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: flex-start;
    text-decoration: none;
}

.title {
    font-family: 'Regular';
    font-size: 14px;
    font-weight: 500;
    color: $text-black;
    margin: 0;
    word-wrap: break-word;
    word-break: break-word;
    width: 100%;
    white-space: normal;
    z-index: 10000;
}

.preview {
    border: 1px solid #eaeaea;
    background: white;
    width: 330%;
    border-radius: 10px;
    margin: -50px -78px -50px;
    margin-top: 8px;
    transform: scale(0.3);
    zoom: 3.6;
    scrollbar-width: 0px;
    scrollbar-color: white;
}

.highlightWrapper {
    margin: 0;
}

.highlight {
    font-family: 'Regular';
    font-size: 13px;
    color: $text-black;
    background-color: #fffece;
    display: inline;
    line-height: 20px;
    margin: 0;
}

.highlight-wrapper {
    margin: 12px 0px;
}

.highlightContainer {
    display: flex;
    flex-direction: column;
    margin: 8px 0px 8px 28px;
    gap: 8px;
}

.capture {
    margin: 8px 28px;
    width: 70%;
    padding: 10px;
    border: 4px solid white;
    // border: 1.5px solid #389eff;
    border-radius: 10px;
    box-shadow: 2px 2px 12px 4px #f5f5f5;
    transition: 0.4s ease-in;
    cursor: zoom-in;
}

.captureDate {
    position: absolute;
    bottom: 7px;
    right: 24px;
    text-align: right;
    color: white;
    background-color: #6c6c6c;
    opacity: 0.85;
    // background-color: #389eff;
    // color: #656565;
    // background-color: #f1f1f1;
    padding: 3px 6px;
    border-radius: 20px;
}

.captureContainer {
    position: relative;
    transition: 0.4s;
}

.captureZoom {
    position: fixed;
    box-sizing: border-box;
    width: 0px;
    border-radius: 20px;
    opacity: 0;
    transition: 0.3s ease-in-out;
    z-index: 100002;
    cursor: zoom-out;
}