@import 'variables.scss';

.container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    transition: 0.1s;
    border-radius: 10px;
    text-decoration: none;
    box-sizing: border-box;
    padding: 0px;
    position: relative;
}

.favicon {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    padding: 4px;
    // background-color: #f5f5f5;
}

.disabledUrl {
    text-decoration: none;
    pointer-events: none;
}

.metadata {
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}

.title {
    font-family: 'Regular';
    font-size: 14px;
    font-weight: 500;
    color: $text-black;
    margin: 0;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    align-self: center;
}

.preview {
    border: 1px solid #eaeaea;
    background: white;
    width: 330%;
    border-radius: 10px;
    margin: -50px -78px -50px;
    margin-top: 8px;
    transform: scale(0.3);
    zoom: 3.6;
    scrollbar-width: 0px;
    scrollbar-color: white;
}

.numHighlights {
    top: -4px;
    left: -10px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 50%;
    background-color: #FFFEBF;
    color: $text-black;
    font-size: 10px;
    font-family: 'Regular';
    text-align: center;
    position: absolute;
    vertical-align: middle;
}

.faviconContainer {
    position: relative;
}

.arrow {
    position: absolute;
    top: 4px;
    right: 4px;
    padding: 6px;
    width: 10px;
    height: 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.44388 1.11634C9.41606 1.0535 9.37668 0.994538 9.32575 0.942673C9.26788 0.883732 9.19769 0.836931 9.11934 0.80644C9.19315 0.835103 9.26234 0.879227 9.32192 0.938812M8.90223 0.766602H2.50634C2.1816 0.766602 1.91835 1.02986 1.91835 1.3546C1.91835 1.67934 2.1816 1.94259 2.50634 1.94259H7.48665L0.853857 8.57569C0.624235 8.80532 0.624235 9.17762 0.853857 9.40726C1.08348 9.63689 1.45577 9.63689 1.68539 9.40726L8.31816 2.77419V7.75546C8.31816 8.0802 8.58142 8.34346 8.90616 8.34346C9.2309 8.34346 9.49415 8.0802 9.49415 7.75546V1.3546C9.49415 1.26979 9.4762 1.18917 9.44388 1.11634' fill='%23989898' style='fill:%23989898;fill:color(display-p3 0.5961 0.5961 0.5961);fill-opacity:1;'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.1s;
    text-decoration: none;
    cursor: pointer;
}

.visitsContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2px; //6px;
    position: fixed;
    margin-top: -14px;
    left: calc(50vw + 230px);
    width: calc(50vw - 250px);
    max-height: 30vh;
    gap: 2px; //4px;
}

.visit {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    gap: 4px;
    border-radius: 8px;
    //padding: 6px 12px;
    padding: 4px 8px;
    font-family: 'Regular';
    font-size: 14px;
    // color: white;
    color: $text-black;
    // background-color: #ffffff;
    // box-shadow: 0 0 0 3px hsla(0,0%,100%,.376);
    box-sizing: border-box;
    cursor: pointer;
    opacity: 0.4;
    transition: 0.15s;
}

.url {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 8px 24px 8px 12px;
    text-decoration: none;
}

.info {
    padding: 4px 8px;
    padding-right: 0;
    margin: 0;
}

.dot {
    width: 5px;
    height: 5px;
    box-shadow: 0 0 0 1.5px white;
    flex-shrink: 0;
}

.focus {
    font-family: 'Medium-Lighter';
}

.strollIcon {
    flex-shrink: 0;
    margin-left: -3px;
}