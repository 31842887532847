@import 'variables.scss';

.day {
    display: flex;
    flex-direction: column;
    width: 300px;
    position: relative;
    flex-shrink: 0;
    overflow-y: auto;
    overflow-x: hidden;
    direction: ltr;
    max-height: calc(100% - 64px); // Offset scroll height (scrollable container has 64px top margin)
}

.historyItemContainer {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 0;
    border-left: $divider;
}

.historyItemContainer > :last-child > div {
    margin-bottom: 40px;
}

.content {
    width: 100%;
    height: 100%;
    z-index: 0;
}

.day:first-child {
    padding-right: calc(50vw - 150px);
}

.day:first-child .historyItemContainer {
    border-left: $divider;
    border-right: $divider;
}

.day:last-child {
    padding-left: calc(50vw - 150px);
}

.title {
    font-family: 'Medium';
    font-size: 14px;
    text-align: center;
    width: 100%;
    margin: 0px;
    padding: 16px 0px;
    border-bottom: $divider;
    position: sticky;
    background: white;
    top: 0px;
    z-index: 1;
}

.year {
    font-family: 'Regular';
    font-size: 12px;
    color: #a2a2a2;
    text-align: center;
    width: 100%;
    margin: 0px;
    padding-left: 8px;
    vertical-align: 5%;
}

.emptyHistory {
    font-family: 'Medium';
    font-size: 14px;
    text-align: center;
    padding: 30px;
}