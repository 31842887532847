@import 'variables.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 200px;
    padding: 8px 16px;
    background-color: white;
    border-radius: 8px;
    position: absolute;
    z-index: 10;
    box-shadow: 2px 2px 12px 4px #f5f5f5;
    top: 68px;
    right: 34px;
}

.desc {
    font-family: 'Regular';
    font-size: 12px;
    color: #a2a2a2;
    margin-top: 8px;
}

.input {
    background: #f8f8f8;
    border: none;
    color: $text-black;
    font-size: 13px;
    align-self: center;
    outline: none;
    padding: 3px 4px;
    font-family: 'Regular';
    word-break: break-word;
}

.focusContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-height: 200px;
    overflow: scroll;
    margin-bottom: -6px;
}

.focus {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    cursor: pointer;
}

.focusName {
    font-family: 'Regular';
    font-size: 13px;
    color: $text-black;
    margin: 0;
    padding: 3px 8px;
    border-radius: 4px;
    width: fit-content;
}

.inputWrapper {
    display: flex;
    flex-wrap: wrap;
    background-color: #f8f8f8;
    gap: 4px;
    flex-direction: row;
    border-radius: 4px;
    padding: 6px 8px;
    margin-bottom: 12px;
}

.filteredFocusWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
}

.removeFocus {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='9' viewBox='0 0 9 9' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.635665 7.12272C0.340511 7.41783 0.340511 7.89629 0.635665 8.19139C0.930819 8.4865 1.40936 8.4865 1.70451 8.19139L4.41404 5.48231L7.12401 8.19183C7.41917 8.48694 7.89771 8.48694 8.19286 8.19183C8.48801 7.89673 8.48801 7.41827 8.19286 7.12316L5.48289 4.41364L8.19292 1.70406C8.48807 1.40896 8.48807 0.930496 8.19292 0.635391C7.89776 0.340286 7.41922 0.340286 7.12407 0.635392L4.41404 3.34497L1.70446 0.635835C1.4093 0.34073 0.930765 0.34073 0.635611 0.635835C0.340457 0.93094 0.340457 1.4094 0.635611 1.70451L3.34519 4.41364L0.635665 7.12272Z' fill='black' style='fill:black;fill:black;fill-opacity:1;'/%3E%3C/svg%3E");
    width: 9px;
    height: 9px;
    padding: 5px 8px 5px 5px;
    margin-left: -7px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.4;
}

.noFilters {
    font-family: 'Regular';
    font-size: 13px;
    color: $text-black;
    margin: 8px;
}

.topGradient {
    height: 16px;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    background: linear-gradient(#FFFFFF, #FFFFFF32);
}

.bottomGradient {
    height: 16px;
    position: absolute;
    bottom: 16px;
    left: 0;
    width: 100%;
    background: linear-gradient(#FFFFFF32, #FFFFFF);
}