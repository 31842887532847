@import 'variables.scss';

.wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: rgb(194 232 255 / 54%); //rgb(219 225 230 / 54%);//rgb(65 165 225 / 54%); //#80808089;
    backdrop-filter: blur(3px);
}

.container {
    position: absolute;
    top: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    width: 400px;
}

.input {
    width: 100%;
    border-radius: 12px;
    padding: 10px 20px;
    font-size: 15px;
    line-height: 1;
    background: white;
    box-shadow: 0 0 0 3px #ffffff60;
    height: 35px;
    border: none;
    box-sizing: content-box;
}

input:focus {
    outline: none;
}

.suggestedItemsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% + 16px);
    padding: 16px 12px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 0 0 3px hsla(0,0%,100%,.376);
    gap: 4px;
    max-height: 30vh;
    overflow-y: scroll;
}

.suggestedCluster {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}

.time {
    font-family: 'Regular';
    font-size: 12px;
    color: #a2a2a2;
    margin: 6px 12px;
}

.gradient {
    position: absolute;
    bottom: 0px;
    left: -20px;
    height: 40px;
    width: calc(100% + 40px);
    background: linear-gradient(0deg, #fff, #ffffff00);
    border-radius: 12px;
}