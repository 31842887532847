@font-face {
    font-family: 'Regular';
    src: url('../public/fonts/Regular.ttf');
}

@font-face {
    font-family: 'Medium';
    src: url('../public/fonts/Medium.ttf');
}

@font-face {
    font-family: 'Bold';
    src: url('../public/fonts/Bold.ttf');
}

@font-face {
    font-family: 'Book';
    src: url('../public/fonts//SuisseIntl-Book.ttf')
}

@font-face {
    font-family: 'Medium-Lighter';
    src: url('../public/fonts//SuisseIntl-Medium.ttf')
}

$divider: 1px solid #f0f0f0;
$text-black: #252525;
$button-border-radius: 8px;
$button-padding: 8px 12px;